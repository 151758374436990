<template>
  <div class="app-wrapper">
    <BaseNavigation />
    <DividerNavigation />
    <div class="p-2 mx-auto">
      <div class="d-flex flex-row">
        <div style="width: 50%" class="pr-25">
          <b-button
            :variant="`${!filterJadwalKRIP ? 'outline-' : ''}danger`"
            block
            @click="filterJadwalKRIP = !filterJadwalKRIP"
          >
            {{ filterJadwalKRIP ? "Batalkan " : "Filter" }}
          </b-button>
        </div>
        <div style="width: 50%" class="pr-25">
          <b-button
            :variant="`${!sortJadwalKRIP ? 'outline-' : ''}danger`"
            block
            @click="sortJadwalKRIP = !sortJadwalKRIP"
          >
            {{ sortJadwalKRIP ? "Batalkan " : "Sort" }}
          </b-button>
        </div>
      </div>
      <!-- filter by krip-->
      <b-form v-if="filterJadwalKRIP" class="mt-1" @submit.prevent>
        <validation-observer ref="filterJadwalKRIP">
          <b-form-group label="Tanggal" label-for="tanggal">
            <validation-provider #default="{ errors }" name="tanggal">
              <b-form-input
                id="tanggal"
                v-model="filter.kripjadwal_tanggal"
                :state="errors.length > 0 ? false : null"
                name="tanggal"
                type="date"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Tempat" label-for="tempat">
            <validation-provider #default="{ errors }" name="tempat">
              <b-form-input
                id="tempat"
                v-model="filter.kripjadwal_tempat"
                :state="errors.length > 0 ? false : null"
                name="tempat"
                type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Status" label-for="status">
            <validation-provider #default="{ errors }" name="status">
              <b-form-select
                v-model="filter.kripjadwal_status"
                :state="errors.length > 0 ? false : null"
                :options="optionStatus"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            type="submit"
            variant="outline-danger"
            block
            @click="validateFilterKRIP"
          >
            Submit
          </b-button>
        </validation-observer>
        <hr />
      </b-form>
      <!-- sort by krip-->
      <b-form v-if="sortJadwalKRIP" class="mt-1" @submit.prevent>
        <validation-observer ref="sortJadwalKRIP">
          <b-form-group label="Sort" label-for="sort">
            <validation-provider #default="{ errors }" name="sort">
              <b-form-select
                v-model="filter.sort"
                :state="errors.length > 0 ? false : null"
                :options="optionSort"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Type Sort" label-for="type_sort">
            <validation-provider #default="{ errors }" name="type_sort">
              <b-form-select
                v-model="filter.typeSort"
                :state="errors.length > 0 ? false : null"
                :options="optionTypeSort"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            type="submit"
            variant="outline-danger"
            block
            @click="validateSortJadwalKRIP"
          >
            Submit
          </b-button>
        </validation-observer>
        <hr />
      </b-form>

      <div class="mt-1">
        <b-card
          v-for="item in schedule.data"
          :key="item.id"
          class="shadow-none border mb-1"
          no-body
        >
          <div
            class="d-flex p-1 border-bottom"
            :class="item.is_regis ? 'bg-warning text-white' : ''"
          >
            <div>
              <div class="font-weight-bold">#JADWAL-{{ item.id }}</div>
              <!-- <b-badge variant="light-danger font-weight–light mt-25">
                Belum terverifikasi
              </b-badge> -->
            </div>
            <div class="ml-auto pointer">
              <b-button
                v-if="
                  item.kripjadwal_status === 'pendaftaran-dibuka' &&
                  !item.is_regis
                "
                size="sm"
                class="bg-primary bg-lighten-1"
                @click="daftarKrip(item.id)"
              >
                Daftar
              </b-button>
              <b-button
                v-if="
                  item.kripjadwal_status === 'pendaftaran-dibuka' &&
                  item.is_regis
                "
                size="sm"
                class="bg-primary bg-lighten-1"
                @click="batalkanKrip(item.id)"
              >
                Batalkan
              </b-button>
            </div>
          </div>
          <div class="p-1">
            <table>
              <tbody>
                <tr>
                  <td>Tanggal</td>
                  <td class="font-weight-bold">
                    : {{ item.kripjadwal_tanggal }}
                  </td>
                </tr>
                <tr>
                  <td>Tempat</td>
                  <td class="font-weight-bold">
                    : {{ item.kripjadwal_tempat }}
                  </td>
                </tr>
                <tr>
                  <td>Jam / Durasi</td>
                  <td class="font-weight-bold">
                    : {{ item.kripjadwal_jam }} WIB /
                    {{ item.kripjadwal_durasi }} Menit
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td class="font-weight-bold">
                    : {{ item.kripjadwal_status }}
                  </td>
                </tr>
                <template
                  v-if="
                    item.kripjadwal_link &&
                    item.kripjadwal_status === 'pendaftaran-dibuka' &&
                    item.is_regis
                  "
                >
                  <tr>
                    <td><b>Link</b></td>
                    <td class="font-weight-bold">
                      :
                      <a
                        :href="item.kripjadwal_link"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Buka
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td><b>Zoom ID</b></td>
                    <td class="font-weight-bold">
                      : {{ item.kripjadwal_zoom_id }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Password</b></td>
                    <td class="font-weight-bold">
                      : {{ item.kripjadwal_pass }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </b-card>

        <div
          v-if="schedule.isLoading"
          class="d-flex justify-content-center mb-1"
        >
          <b-spinner label="Loading..." variant="danger" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import {
  BButton,
  BCard,
  BImg,
  BSpinner,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormFile,
  // BBadge,
} from "bootstrap-vue";
import BaseNavigation from "@/components/Base/BaseNavigation.vue";
import DividerNavigation from "@/components/Base/DividerNavigation.vue";
// import { required, email } from '@validations'
import apis from "@/api";
import storage from "@/helpers/storage";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BaseNavigation,
    DividerNavigation,
    BButton,
    BCard,
    BFormInput,
    BFormSelect,
    BImg,
    BSpinner,
    BForm,
    BFormGroup,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    // BBadge,
  },
  data() {
    return {
      required,
      examplePicStudiKasus: require("@/assets/images/pages/Studi_Kasus_Contoh.jpeg"),
      schedule: {
        data: null,
        isLoading: false,
        nextPageUrl: null,
      },
      tempFileBerkas: null,
      fileBerkas: null,
      filterJadwalKRIP: false,
      sortJadwalKRIP: false,
      filter: {
        kripjadwal_tanggal: "",
        kripjadwal_tempat: "",
        kripjadwal_status: "",
        sort: "",
        typeSort: "",
      },
      optionStatus: ["pendaftaran-dibuka", "pendaftaran-ditutup", "selesai"],
      optionSort: [
        "kripjadwal_tanggal",
        "kripjadwal_tempat",
        "kripjadwal_status",
      ],
      optionTypeSort: ["asc", "desc"],
    };
  },
  computed: {
    // https://www.idijakpus.or.id/uploads/krip/krip_file/1473/A2.png
    getKRPFile() {
      return (item) => {
        if (item.krip_file) {
          if (!item.krip_file.includes("https")) {
            return `https://www.idijakpus.or.id/uploads/krip/krip_file/${item.id}/${item.krip_file}`;
          }
          return item.krip_file;
        }
        return null;
      };
    },
    isPDF() {
      return (item) => {
        if (item.krip_file?.includes(".pdf")) {
          return true;
        }
        return false;
      };
    },
  },
  mounted() {
    document
      .getElementsByClassName("app-wrapper")[0]
      .addEventListener("scroll", this.scrollCallback);
  },
  created() {
    this.fetchSchedule();
  },
  methods: {
    viewPdf(url) {
      storage.setStorage("pdfUrl", url);
      this.$router.push({ path: "/pdfview" });
    },
    scrollCallback() {
      const element = document.getElementsByClassName("app-wrapper")[0];
      const { scrollTop } = element;
      const { scrollHeight } = element;
      const { clientHeight } = element;
      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        if (!this.schedule.isLoading && this.schedule.nextPageUrl) {
          this.fetchSchedule(this.schedule.nextPageUrl);
        }
      }
    },
    fetchSchedule(url) {
      this.schedule.isLoading = true;
      apis.krip
        .getSchedule(url, this.filter)
        .then(({ data }) => {
          if (url && this.filter) {
            this.schedule.data = this.schedule.data.concat(data.data);
          } else {
            this.schedule.data = data.data;
          }
          this.schedule.nextPageUrl = data.next_page_url;
        })
        .finally(() => {
          this.schedule.isLoading = false;
        });
    },
    daftarKrip(id) {
      this.$store.commit("app/UPDATE_LOADING_BLOCK", true);
      apis.krip
        .daftar({
          kripjadwal_id: id,
        })
        .then(() => {
          this.fetchSchedule();
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Berhasil mendaftar!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.filterJadwalKRIP = false;
          this.fetchKrips();
        })
        .catch((error) => {
          this.errorHandler(error, "Gagal daftar, silahkan coba lagi nanti");
        })
        .finally(() => {
          this.$store.commit("app/UPDATE_LOADING_BLOCK", false);
        });
    },
    batalkanKrip(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah membatalkan, anda tetap bisa mendaftar kembali",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Batalkan!",
        cancelButtonText: "Tutup",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.$store.commit("app/UPDATE_LOADING_BLOCK", true);
            return apis.krip.batal(id);
          }
          return false;
        })
        .then((result) => {
          if (result) {
            this.$store.commit("app/UPDATE_LOADING_BLOCK", false);
            this.fetchSchedule();
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Berhasil membatalkan pendaftaran",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((error) => {
          this.errorHandler(
            error,
            "Gagal membatalkan pendaftaran, silahkan coba lagi nanti"
          );
        });
    },
    validateFilterKRIP() {
      this.$refs.filterJadwalKRIP.validate().then((success) => {
        if (success) {
          this.fetchSchedule();
        }
      });
    },
    validateSortJadwalKRIP() {
      this.$refs.sortJadwalKRIP.validate().then((success) => {
        if (success) {
          this.fetchSchedule();
        }
      });
    },
  },
};
</script>

<style></style>
